.logoutbutton {
    font-size: 20px;
    color: orange;
    margin-top: 8px;  
    margin-bottom: 20px;
  }
  
  .logoutbutton:hover {
  
    color: black;
  }
  
  .logoutbutton:focus {
    background-color: #ff7f50;
    outline: none;
  }
  
 