/* account.css */

.accountpage {
  background-color: rgb(237, 237, 237);
}

.accountcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 30px;
  gap:3rem;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  border-radius: 12px;
  background-color: white;
  width: 28%;
}

.profile-details {
  width: 88%;
  display: flex;
  align-items: center;
  /* background-color: yellow; */
}
.first{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: end;
  gap:5px;
  margin-right: 10px;
  /* background-color: blue; */
}
.first h6{
  text-transform: uppercase;
  font-size: 11px;
}
.first .settings-icon{
  cursor: pointer;
}
.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}
.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1; /* Allow the profile info to grow and take up remaining space */
}

.profile-info h2 {
  margin-bottom: 2px; /* Add some spacing between the display name and the icon */
  font-size: 18px;
}
.email{
  word-wrap: break-word;
  font-size: small;
}


.accheader{
  font-size: 20px;
  color:gray;
  margin-bottom: 13px;
  text-align: center;
  background-color: rgb(237, 237, 237);
}
.acclinks {
  margin-top: 40px;
  /* background-color: green; */
  width: 100%;
}

.acclinks ul {
  list-style-type: none;
  padding: 0;
}
.arrow-icon {
  float: right; /* Align the arrow to the right */
  color:black;
  
}

.acclinks li {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}
.acclinks li section{
  
  width: 90%;
}

.acclinks a {
  text-decoration: none;
  color:black;
  width: 80%;
}

.acclinks a:hover {
  text-decoration: underline;
}
.fsticon{
  margin-right: 8px;
}
.myadsacc {
  border: 1px solid #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  
}

.myadsacc h2 {
   text-align: center;
  font-size: 25px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  
}
@media only screen and (min-width: 768px) {
  #myads {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .accountcontainer {
    margin: 0px; /* Adjust margin for smaller screens */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
       
  }
 
  .profile-container {
    width: 100%; /* Adjust width for smaller screens */
    margin-bottom: 50px;
  }
  
.profile-details {
  display: flex;
  align-items: center;
  gap:2rem;
  
}
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1; /* Allow the profile info to grow and take up remaining space */
    
  }
  .accheader{
    font-size: 23px;
    margin-bottom: 15px;
    color:gray;
    background-color: rgb(237, 237, 237);
   
  }
.acclinks {
  margin-top: 40px;
  
}

.acclinks ul {
  list-style-type: none;
  padding: 0;
}

.acclinks a {
  text-decoration: none;
  font-size: 20px;
}

  .myadsacc {
     display: none;
  }

 
  /* Style for the list items */
  .acclinks li {
    position: relative; /* Set the position of the list item to relative */
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
}