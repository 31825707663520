
.home{
  margin: 0;
  background-color: rgb(237, 237, 237);
  
}

.searchbg{

  background-color: maroon;
  padding: 80px;
}
.container {
  caret-color: transparent !important;
  display: flex;
  justify-content: center;
  margin: 35px;
  

}

.left {
  width: 30%;
  
}

.right {
  width: 75%;
  
  
}
.right .products{
  margin-top: -25px;
}
/* Media Query for mobile responsiveness */
@media (max-width: 768px) {
  .home {
    overflow-x: hidden;
  }
  .searchbg{

    background-color: maroon;
    padding: 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* justify-content: space-between; */
    
  }
  
  .container .left {
    margin-top: -15px;
    margin-left: -5px;
    width: 100%;
    
    /* background-color: lightblue; */
  }
  
  .container .right {
    width: 95%;
    margin-top: 25px;
    margin-left: -27px;
    margin-bottom: 80px;
    }
  .right .products{
    margin-top: -25px;

    
  }
  .ad{
    display: none;
  }
}