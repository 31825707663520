.wishlistpage{background-color: rgb(237, 237, 237);}
.wishlist-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: rgb(237, 237, 237);
    height: 100vh;
  }
  .loader{
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:200px;
    max-width: 90%;
  }
  .empty-wishlist {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(237, 237, 237);
  }
  
  .empty-wishlist p {
    font-size: 18px;
    color: #555;
  }
  .savedheader{
   font-size: 30px;
   text-align: center;
   margin-top: 10px;
   width: 99.9%;
   margin-left: 0px;
   margin-right: 0px;
   background-color: white;
   margin-bottom: 25px;
  }
  .savedproductcard-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 columns with equal width */
    gap: 13px; /* Gap between each card */
 }
 .savedproductcard {
    background-color: white;
    overflow: hidden;
    border: 1px solid #ffffff; /* Border color and width */
    border-radius: 8px; /* Border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow definition */
    /* Additional styles for the product card if needed */
    /* width: 220px; */
    display: flex;
    
 }
 .savedimg{
   height: 200px;

 }
 .saved_image {
    height: 100%; /* Make the image take up the full height of the carousel */
  object-fit: cover; /* Maintain aspect ratio and cover entire space */
 }
 
 
 .saved_title {
    color: black;
    font-size: 16.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(20.5px * 10); /* Assuming 16.5px is the width of each character */
    text-transform: capitalize; /* Capitalize the first letter of each word */
 }
 
 .saved_details{
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .removebtn{
  background-color: orange;
  padding: 5px;
  border-radius: 5px;
  width: 95%;
  float:right;
  
  }
  .removebtn:Hover{
   background-color: rgb(221, 148, 11);
  }

  @media only screen and (max-width: 600px) {
    .wishlistpage{
        background-color: rgb(237, 237, 237);
    }
    .wishlist-container{
      width:100%;
      height: 100vh;
    }
   
    .savedproductcard-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* 4 columns with equal width */
      gap: 13px; /* Gap between each card */
   }
   .saved_image {
    height: 100%;
    width: 50%;
 }
 .saved_title {
  color: black;
  font-size: 16.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(14.5px * 10); /* Assuming 16.5px is the width of each character */
  text-transform: capitalize; /* Capitalize the first letter of each word */
}
 
  }
  