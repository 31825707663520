.productcontainer{
  background-color: rgb(237, 237, 237);
}

.product {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  gap: 1.5rem;
  
}
.wishlisticon{
  cursor: pointer;
}
.wishlisticon:hover{
  /* background-color:red; */
  fill:#800000;
}
.carousel {
  display: flex;
  scroll-snap-type: x mandatory;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  border-radius: 10px;
  scroll-behavior: smooth; /* Smooth scrolling */
  height: 600px; /* Set a fixed height for the carousel */
}

/* Hide scrollbar for WebKit browsers */
.carousel::-webkit-scrollbar {
  display: none;
}

.carousel-image {
  flex: 0 0 100%; /* Ensure each image takes up the full width of the carousel */
  scroll-snap-align: start;
  height: 100%; /* Make the image take up the full height of the carousel */
  object-fit: cover; /* Maintain aspect ratio and cover entire space */
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.1); /* Darken background on hover */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%; /* Circular shape for arrows */
  transition: background 0.3s ease; /* Smooth transition on hover */
}

.arrow:hover {
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
}

.arrow-left {
  left: 40px;
  top: 375px;
}

.arrow-right {
  right: 525px;
  top: 375px;
}
.wishlisticon{
  cursor: pointer;
  fill:white;
  right: 515px;
  top: 125px;
  position: absolute;
}
.wishlisticon:hover{
  /* background-color:red; */
  fill:#800000;
}
.wishlisticonmobile{display:none;}

.imgleft{
  width: 70%;
 
}
.image-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.8); */
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-image {
  margin-top: 70px;
  max-width: 90%;
  max-height: 90%;
}

.full-screen-overlay .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
}

.full-screen-overlay .arrow-left {
  left: 10px;
}

.full-screen-overlay .arrow-right {
  right: 10px;
}
.detailsright{
  width: 35%;  
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-right: 30px;
  padding: 5px;
  
}

.pricediv {
  margin-bottom: 20px;
  width: 100%;
  padding: 15px 20px; /* Adjusted padding */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  display:flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
  justify-content: center;
}

.pricediv .propertyprice {
  font-size: 32px;
  font-weight: 400;
  color: #333333; /* Dark text color */
}

.pricediv .paymentper {
  font-size: 15px;
  color: #333333; /* Dark text color */
}
/* Chat container */
.chat {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  width:100%;
}
.chatmobile{display: none;}


.chat .phoneNumber{
  font-size: 25px;
  text-align: center;
}

.chat input {
  padding: 12px;
  width: 100%;
  height: 45px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  margin-bottom: 10px;
}

.chat .sendbtn, .showphonenumber, .addtowishlist{
  background-color: #800000; /* Maroon color */
  color: #ffffff;
  padding: 10px 0; /* Adjusted padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 100%; /* Button takes full width */
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Hover effect */
.chat .sendbtn:hover {
  background-color: #660000; /* Darker maroon on hover */
}
.chat .showphonenumber:hover {
  background-color: #660000; /* Darker maroon on hover */
}
.chat .addtowishlist:hover {
  background-color: #660000; /* Darker maroon on hover */
}

.chat .postedby {
  font-size: 20px;
  color: #333333; /* Dark text color */
}


.detailscontainer {
  width: 62%;
  font-family: Arial, sans-serif;
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  background-color: #f9f9f9;
}

.propertytitle {
  color: #333;
  font-size: 24px;
}

.category {
  color: #666;
  font-size: 18px;
}

.location {
  color: gray;
  font-size: 16px;
}

.dateposted {
  color: #666;
  font-size: 14px;
}

.description {
  color: #444;
  font-size: 16px;
}

.otherdetails {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
  gap: 10px; /* spacing between grid items */
}

.otherdetails h2 {
  color: #666;
  font-size: 16px;
  margin-bottom: 5px;
}

.similarproducts{
  margin-top: 20px;
  margin-left: 30px;
  width:65%;
  margin-bottom: 20px;
  }


@media (max-width: 768px) {
  .productcontainer {
    background-color: rgb(237, 237, 237);
  }
  
  .product {
    display: flex;
    flex-direction: column;
    margin: 8px;
    gap: 1.5rem;
  }
  
  .carousel {
    display: flex;
    scroll-snap-type: x mandatory;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    border-radius: 10px;
    scroll-behavior: smooth; /* Smooth scrolling */
    height: 350px; /* Set a fixed height for the carousel */
  }
  
  .arrow {
    position: absolute;
    top: 500%;
    transform: translateY(-500%);
    background: rgba(255, 255, 255, 0.8);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background 0.3s ease;
  }
  
  .arrow:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .arrow-left,.arrow-right {
    display:none;
  }
  .wishlisticonmobile{
    cursor: pointer;
    right: 16px;
    top: 75px;
    position: absolute;
  }
  
  .imgleft {
    width: 100%;
  }
  
  .detailsright {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  
  .pricediv {
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: baseline;
  }
  
  .pricediv .propertyprice {
    font-size: 27px;
    
  }
  
  .chat { display:none;  }
  .chatmobile {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 10px;
    
  }
  
.chatmobile input {
  padding: 12px;
  width: 100%;
  height: 45px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  margin-bottom: 10px;
}

  .chatmobile .sendbtn{
    background-color: #800000; /* Maroon color */
    color: #ffffff;
    padding: 10px 0; /* Adjusted padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    width: 100%; /* Button takes full width */
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .chatmobile .showphonenumber, .addtowishlist{
    background-color:maroon;
    color: #ffffff;
    padding: 10px 100px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    width: 100%; 
  }
    
  /* Style for when input is focused */
  .chatmobile input:focus {
    border-color: orange; /* Change border color on focus */
  }
  
  .chatmobile .postedby {
    font-size: 20px;
  }
  
  .detailscontainer {
    margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 25px;
    width:95%;
  }
  
  .detailscontainer .propertytitle {
    font-size: 25px;
  }
  
  .detailscontainer .location,
  .detailscontainer .category,
  .detailscontainer .dateposted {
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .otherdetails {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets and larger screens */
  }
  .similarproducts{
  align-items: center;
  margin-left: 5px;
  margin-bottom: 80px;
  width: 97%;
}
}

@media (min-width: 992px) {
  .otherdetails {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop screens */
  }
}