.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 200px; /* Default padding for larger screens */
  background-color: #f8f9fa; /* Assuming light background */
}

.error-heading {
  color: maroon;
  font-size: 6rem;
  margin-bottom: 0;
}

.error-message {
  color: #343a40; /* Darker color for better readability */
  font-size: 1.5rem;
  margin-top: 0.5rem;
}
.error-icon {
  
  color: red; /* Adjust the color as needed */
}

/* Media query for screens smaller than 768px (typical mobile devices) */
@media screen and (max-width: 767px) {
  .not-found-container {
    padding: 20px; /* Adjusted padding for mobile */
  }

  .error-heading {
    font-size: 3.5rem; /* Adjusted font size for mobile */
    text-align: center; /* Center the text */
  }

  .error-message {
    font-size: 1.2rem; /* Adjusted font size for mobile */
    text-align: center; /* Center the text */
  }
}
