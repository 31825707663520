.myads{
   padding: 15px 30px;
}
.myadspage{
   background-color: rgb(237, 237, 237);
   
}
.myadspage .backtoaccount{
   font-size: 20px;
   margin-left: 10px;
   display: flex;
   align-items: center;
   
}
.myadspage .backicon{
   font-size: 20px;
   
   
}
.myadspage .myadsheader{
   font-size: 30px;
   text-align: center;
   margin-top: 10px;
   width: 99.9%;
   margin-left: 0px;
   margin-right: 0px;
   background-color: white;
}

.myads .productcard-container {
   margin: 2px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 columns with equal width */
    gap: 13px; /* Gap between each card */
 }
 
.myadspage .myads .productcard-container {
   margin: 2px;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4 columns with equal width */
    gap: 13px; /* Gap between each card */
 }
 .no-ads{
    padding: 250px 10px;
 }
 .no-ads-message{
    color:gray;
    font-size: 25px;
 }
 
 .Deleteicon{
   color:maroon;
   float: right;
   cursor: pointer;
 }
 
 .Deleteicon :hover{
   color:red;
   
 }
 
@media only screen and (max-width: 600px) {
   .myadspage{
      margin-bottom: 65px;
   }
   .myadspage .myads .productcard-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
      gap: 13px; /* Gap between each card */
      
      margin-left: -10px;
      margin-right: 0px;
   }
   .myadspage .myadsheader{
      font-size: 25px;
      text-align: center;
      margin-top: 10px;
      width: 100%;
      background-color: white;
   }
   
 }