.products{
   background-color: rgb(237, 237, 237);
   max-height: 100vh;
}
.outter{
  margin: 15px;

}
.outter .cramp{
   display: flex;
}
.outter .maincontainer{
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   gap: 2rem;
}
.productscontainer{
   margin-top: 50px;
}
 .productcard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
    gap: 13px; /* Gap between each card */
 }
 .productcard {
    overflow: hidden;
    border: 1px solid #ffffff; /* Border color and width */
    border-radius: 8px; /* Border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow definition */
    /* Additional styles for the product card if needed */
    width: 220px;
 }
 
 .product_image {
    height: 150px;
    width: 100%;
 }
 .loader-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%; /* Ensures the container takes full height of its parent */
 }
 
 .product_title {
    color: black;
    font-size: 16.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(16.5px * 10); /* Assuming 16.5px is the width of each character */
    text-transform: capitalize; /* Capitalize the first letter of each word */
 }
 
 .product_details{
    background-color: white;
    padding: 10px;
 }
 .product_price {
    color: maroon;
    font-size: 16.5px;
    font-weight:500;
 }
 .property_location {
    color: gray;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(16.5px * 10); /* Assuming 16.5px is the width of each character */
    text-transform: capitalize; /* Capitalize the first letter of each word */
 }
 .filterbox{
   display: flex;
   flex-direction: column;
   gap: 1rem;
   padding: 5px;
   background-color: white;
   border-radius: 12px;
}
.filterbox h2{
   font-size: 18px; /* Adjusted font size for better readability */
}

 @media (max-width: 768px) {
   .products{
      background-color: rgb(237, 237, 237);
      max-height: 100vh;
   }
   .outter{
       
      display: flex;
      flex-direction: column;
      justify-content: center;
      
   }
   .outter .cramp{
      
      display: flex;
      flex-direction: row;
      align-items: end;
      font-size: 16px;
   }
   .outter .cramp h1{
   font-size: 16px;
   }
   .outter .maincontainer{
      display: flex;
      flex-direction: column;
      width: 100%;
          
   }
   
   .productscontainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      
   }
   
   .filterbox{
      display: none;
   }
   .filterbox h2{
      font-size: 18px; /* Adjusted font size for better readability */
   }
   .productsheader{
      font-size: 22px; /* Adjusted font size for better readability */
      margin-bottom: 10px; /* Adjusted margin for better spacing */
   }
   .productcard-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid layout */
      gap: 5px; /* Gap between each card */
      width: 100%; /* Adjusted width for better responsiveness */
   }
   .outter .productcard-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid layout */
      gap: 8px; /* Gap between each card */
      width: 100%; /* Adjusted width for better responsiveness */
   }
   .productcard {
      overflow: hidden;
      border: 1px solid #ccc; /* Border color and width */
      border-radius: 8px; /* Border radius */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow definition */
      width: 100%; /* Adjusted width for better responsiveness */
   }
  
   .product_image {
      height: 120px; /* Adjusted height for better visibility */
      width: 100%;
   }
   .product_title {
      color: black;
      font-size: 14px; /* Adjusted font size for better readability */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; /* Adjusted maximum width */
      text-transform: capitalize; /* Capitalize the first letter of each word */
   }
   .product_details{
      background-color: white;
      padding: 8px; /* Adjusted padding for better spacing */
   }
   .product_price {
      color: maroon;
      font-size: 14px; /* Adjusted font size for better readability */
      font-weight: 500;
   }
   .property_location {
      color: gray;
      font-size: 12px; /* Adjusted font size for better readability */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; /* Adjusted maximum width */
      text-transform: capitalize; /* Capitalize the first letter of each word */
   }
}
