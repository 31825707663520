.menu {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap between menu items */
    padding: 20px; /* Add padding to the menu */
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow effect */
    background-color: white;
  }
  
  .menu .menuitem {
    display: flex; /* Make the menuitem a flex container */
    align-items: center; /* Align items vertically */
  }
  
  .menu img {
    width: 50px; /* Set the width of the image */
    margin-right: 10px; /* Add some margin to the right of the image */
    background-color: transparent; /* Set background color to transparent */
  }
  .menu h1 {
  cursor: pointer;
  padding: 5px;
  font-size: 16px; /* Adjust the font size */
  font-weight:400; /* Make the text bold */
  color: #333; /* Set the text color */
  
  margin-bottom: 5px; /* Add some spacing below the heading */
}
  
  /* Add hover effect */
  .menu .menuitem:hover {
    background-color: #eaeaea;
  }
  
  @media (max-width: 768px) {

    .menu {
      width: 95%;
      margin:0px -21px;
      margin-top: -10px;
      display: grid; /* Use grid layout */
      grid-template-columns: repeat(3, 1fr); /* Create 3 columns with equal width */
      gap: 5px; /* Adjust the gap between menu items */
      padding:2px; /* Add padding to the menu */
      border-radius: 0px; 
      box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2); 
      background-color: rgb(237, 237, 237);
    }
    
    .menu .menuitem {
      display: flex; /* Make the menuitem a flex container */
      align-items: center; /* Align items vertically */
      flex-direction: column;
      background-color: white;
      border-radius: 5px; /* Add border radius */
      padding: 2px; /* Add padding */
      justify-content: center;
     
    }
    
    .menu img {
      width: 80px; /* Set the width of the image */
      background-color: transparent; /* Set background color to transparent */
    }
  
    .menu h1 {
      padding: 5px;
      font-weight: 500;
      font-size: 12px; /* Adjust the font size */
      margin: 0; /* Remove default margin */
      text-align: center; /* Center the text */
      flex: 1; /* Allow the h1 elements to expand to fill the available space */
    }
    
    #houserent{
      height: 80px;
    }
    #postad{
      display: none;
    }
  }
  
  