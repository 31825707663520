.post{ background-color: rgb(237, 237, 237);}
.uploadimg {
  display: flex;
  flex-wrap: wrap;
}

.uploadingImage {
  margin-right: 10px; /* Adjust spacing between images */
  margin-bottom: 10px; /* Adjust spacing between rows */
}

.uploadingImage img {
  width: 100px; /* Adjust image width as needed */
  height: 100px; /* Adjust image height as needed */
  border-radius: 8px;
}
.afterUpload{
  display: flex;
  flex-wrap: wrap;
}

.imgsafterupload{
  margin: 10px;
  height: 100px;
  width:100px;
  border-radius: 8px;
}

.headertitle{
  margin-top: 45px;
  text-align: center;
  background-color: white;
  font-size: 25px;
  margin-left: 200px;
  margin-right: 200px;
  padding: 15px;
  border-radius: 12px;
}
.postcontainer{
  margin:50px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
  margin-right: 200px;
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  
}

.postcontainer input {
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Focus Styles */
input:focus {
  border-color: #007bff; /* Change to your preferred focus color */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional: Add a box shadow on focus */
}

/* Placeholder Styles */
input::placeholder {
  color: #999; /* Adjust placeholder color to your preference */
}

select {
  padding:8px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  appearance: none; /* Removes default appearance */
  margin-bottom: 10px;
}

/* Focus Styles */
select:focus {
  border-color: #007bff; /* Change to your preferred focus color */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional: Add a box shadow on focus */
}

/* Disabled Styles */
select:disabled {
  background-color: #f0f0f0; /* Adjust background color for disabled state */
  cursor: not-allowed; /* Change cursor to indicate the select is disabled */
}

/* Option Styles */
select option {
  padding: 10px;
  font-size: 16px;
}

/* Hover Styles (Optional) */
select option:hover {
  background-color: #f0f0f0; /* Adjust background color on hover */
}
.maroon-button {
  display: inline-block;
  padding: 10px 120px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #800000; /* Maroon color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-bottom:10px;
}

/* Hover Effect */
.maroon-button:hover {
  background-color: #660000; /* Darker maroon on hover */
}
.btndiv{
  display:flex;
  flex-direction:column;
  align-items: center;
}

.required {
  color: red;
  margin-left: 4px;
}

.invalid {
  border: 1px solid red;
}

.error-message {
  color: red;
}



@media (max-width: 768px) {
 
.headertitle{
  margin-top: 45px;
  text-align: center;
  background-color: white;
  font-size: 25px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 2px;
}
.postcontainer{
  margin:50px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  margin-bottom: 80px;
  
}

.maroon-button {
  display: inline-block;
  padding: 5px 110px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #800000; /* Maroon color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

}