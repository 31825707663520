/* Container for the entire chat */
.parent-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    height: 100vh;
    padding-top: 80px; /* Space for fixed chat profile */
    padding-bottom: 60px; /* Space for fixed message form */
}

/* Header */
.heading, .sub-heading {
    margin: 10px;
    color: gray;
}

/* Container for the message list */
.message-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
    flex-grow: 1;
    overflow-y: auto;
    background-color: #e5ddd5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Individual message item */
.message-item {
    display: flex;
    justify-content: flex-start;
    margin: 10px;    
}

/* Message <p> */
.msg {
    word-wrap: break-word;
    overflow-wrap: break-word; /* Ensure text wraps to the next line */
    max-width: 255px;
}

/* Container for the message content */
.message-container {
    padding: 10px;
    border-radius: 10px;
    max-width: 75%;
    display: flex;
    align-items: center;
    position: relative;

}

/* Align messages to the right if they are sent by the current user */
.right-align {
    background-color: #e1abab; /* Maroon color */
    margin-left: auto;
}

/* Align messages to the left if they are received */
.left-align {
    background-color: #fff;
    margin-right: auto;
}

/* Profile section */
.chatprofile {
    padding: 10px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* Profile picture */
.chatprofilepic {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.chatprofile h1 {
    font-size: 22px;
}

/* Message content */
.message-container p {
    margin: 0;
}

/* Message metadata */
.message-container h3 {
    margin: 5px 0 0;
    font-size: 0.8em;
    color: #999;
}

.message-container h6 {
    margin: 5px 0 0;
    font-size: 0.7em;
    color: #aaa;
}

/* Form for sending new messages */
.message-form {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    padding: 10px;
    z-index: 1;
}

.message-form label {
    flex-grow: 1;
}

.message-form input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.message-form button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #800000; /* Maroon color */
    color: #fff;
    cursor: pointer;
}

.message-form button:hover {
    background-color: #902121; /* Maroon color */
}

@media (max-width: 600px) {
   .parent-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    height: 100vh;
    padding-top: 68px; /* Space for fixed chat profile */
    padding-bottom: 60px; /* Space for fixed message form */
} 
  .chatprofile{
    display: flex;
    flex-direction: row;
  }  
  .chatprofilepic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.chatprofile h1 {
    font-size: 18px;
}

}
