.faqs-container {
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    color: maroon;
    
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .faq {
    margin-bottom: 30px;
  }
  
  .question {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .answer {
    font-size: 1.1rem;
  }
  @media (max-width: 768px) {

    .faqs-container {
      /* max-width: 800px; */
    
      padding-bottom: 60px;
    }
    
  }