.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: maroon;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
 .contact-form .form-group {
    margin-bottom: 20px;
  }
  
 .contact-form label {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
 .contact-form input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
 .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form .submit-btn {
    width: 200px;
    margin: 20px auto 0;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: maroon;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #8B0000;
  }
  @media (max-width: 768px) {

    .contact-container {
      /* max-width: 800px; */
    
      padding-bottom: 60px;
    }
    
  }