/* Container for the entire message section */
.msgcontainer {
    background-color: #f0f0f0;
    min-height: 100vh;
}

/* Wrapper for the message content */
.msgdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

/* Loader styles */
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* List styles */
.msgs ul {
    list-style-type: none;
    padding: 0;
}
.sender{
    font-size: 18px;
}

/* Timestamp styles */
h6 {
    color: gray;
    font-size: 13px;
    float: right;
}

/* Individual message item styles */
.msgs ul li {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    gap: 13px;
    align-items: center;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for message items */
.msgs ul li:hover {
    background-color: #f9f9f9;
}

/* Message content truncation */
.themsg {
    white-space: nowrap;         /* Prevents the text from wrapping to a new line */
    overflow: hidden;            /* Hides the overflowed text */
    text-overflow: ellipsis;     /* Adds the ellipsis (...) */
    width: 420px;
    color: gray;
}

/* Media query for responsive design */
@media (max-width: 600px) {
    .msgdiv {
        margin-top: 10px;
    }

    .msgs ul li {
        margin-bottom: 15px;
        padding: 8px;
        border-radius: 6px;
        gap: 10px;
    }

    .themsg {
        width: 250px;
    }
    
    h6 {
        font-size: 10px;
    }
}

/* Additional media query for larger devices */
@media (min-width: 601px) and (max-width: 1200px) {
   .themsg {
      width: 220px;
    }

}
