.Navbar {
  width: 100%;
  padding: 5px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: rgb(146, 21, 21);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Changed shadow */
  z-index: 1000; /* Set a high z-index value to ensure the Navbar is on top */
}

.rightnav {
  display: flex;
  align-items: center;
}

.Logo {
  font-size: 35px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  margin-left: 20px; /* Add margin */
  color: white; /* Changed color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Added text shadow */
}

.nav {
  margin-right: 20px;
}

.nav span {
  margin: 5px 10px;
  padding: 6px 6px; /* Added padding */
  background-color: white; 
  border-radius: 90%; /* Added border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Added shadow effect */
  align-items: center;
  justify-content: center;
 
}

.icon-container {
    position: relative;
    display: inline-block; /* Ensure inline-block so it can contain the text */
  }
  
  .text {
    display: none;
    position: absolute;
    top: calc(100% + 5px); /* Position the text below the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
  }
  
  .icon-container:hover .text {
    display: block; /* Show text on hover */
  }

.Bottomtabnav{
  display: none;
}

.profilepic {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  border-radius: 50%; /* Make the image round */
}

.sellbtn {
  background-color: orange;
  color: white;
  
  border-radius: 10px;
  padding: 8px 40px;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
}
/* Media Query for mobile responsiveness */
@media (max-width: 768px) {
  .Navbar {
    padding: 15px; /* Adjusted padding for better spacing */
  }

  .Logo {
    font-size: 22px; /* Adjusted font size for better readability */
    margin-left: -5px; /* Adjusted margin for better alignment */
  }

  .sellbtn {
    display: none; /* Hide the sell button on smaller screens */
  }

  .nav span {
    padding: 2px; /* Adjusted padding for better spacing */
    margin: 1px 2px; /* Adjusted margin for better spacing */
  }

  .rightnav {
    margin-left: -20px; /* Adjusted margin for better alignment */
  }

  #offnigga {
    display: none; /* Hide element with ID "offnigga" on smaller screens */
  }

  #Account {
    margin-right: -15px; /* Adjusted margin for better alignment */
  }

  .Bottomtabnav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    align-items: center;
    background-color: white;
    padding: 6px 5px; /* Adjusted padding for better spacing */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); 
  }

  .tabitem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tabicon {
    font-size: 20px; /* Adjusted font size for better visibility */
    margin-bottom: 3px; /* Adjusted margin for better spacing */
  }

  .tabicon svg {
    fill: #333; /* Change icon color */
  }

  h1 {
    margin: 0;
    font-size: 12px; /* Adjusted font size for better readability */
    margin-top: -4px; /* Adjusted margin for better spacing */
  }

  #refused {
    margin-bottom: -3px; /* Adjusted margin for better spacing */
  }

  #sellicon {
    font-size: 30px; /* Adjusted font size for better visibility */
    fill:white;
    background-color: maroon;
    padding: 5px;
    border-radius: 30px;
  }

  .activeh1 {
    font-size: 24px; /* Adjusted font size for better readability */
  }
}
