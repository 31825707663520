.terms-conditions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: maroon;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .last-updated {
    margin-bottom: 20px;
    font-style: italic;
  }
  @media (max-width: 768px) {

    .terms-conditions-container {
      /* max-width: 800px; */
    
      padding-bottom: 60px;
    }
    
  }