.footer {
  background-color: maroon;
  color: white;
  padding: 90px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  gap: 10rem;
  margin-left: 25px;
  
}

.footer-column {
  flex: 1;
}

.footer-column h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: gray;
  font-weight: 500;
}

.footer-column ul {
  list-style-type: none;
  padding: 5px 0px;
}

.footer-column ul li {
  margin-bottom: 15px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
  
}

.footer-column ul li a:hover {
  text-decoration: underline;
}
#last{
  margin-top: 80px;
  text-align: center;
  font-size:medium; 
}

/* Styles for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .footer {
    display: none;
  }

  
  .mobile-help Ul{
   list-style-type: none;
    

  }
  .mobile-help Ul li{
   font-size: 19px;
   margin-top: 15px;
   margin-bottom: 17px;
   margin-left: 13px;
   margin-right: 13px;
   color:black;
  }
 .mobile-help .HelpHeader{
    font-size: 19px;
    margin-bottom: 15px;
    margin-top: 15px;
    color:gray;
    background-color: rgb(237, 237, 237);
    text-transform: uppercase;
    padding-left: 10px;
  }
  .mobile-help .text-end{
    color:gray;
    font-size: 18px;
    float:right;
    text-transform: uppercase;
  }
}
