.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: maroon;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .feature-list {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .team-member {
    flex-basis: 30%;
    margin-bottom: 20px;
  }
  
  .team-member-img {
    width: 100%;
    border-radius: 50%;
  }
  
  .member-name {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  
  .member-role {
    font-style: italic;
  }
  @media (max-width: 768px) {

    .about-us-container {
      /* max-width: 800px; */
    
      padding-bottom: 60px;
    }
    
  }