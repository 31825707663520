.banner {
    background-color: green;
    color: white;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-radius: 10px; /* Added border radius */
    margin-bottom: 80px;
  }
  
  .content {
    max-width: 800px; /* Adjust as needed */
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 20px;
  }
  
  .discount {
    margin-top: 20px;
  }
  
  .price {
    color: maroon;
  }
  
  @media (max-width: 768px) {
    .banner {
      background-color: orange;
    }
  }
  