.settingspage {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .userprofile-container {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }
  
  .userprofile-details {
    display: flex;
    align-items: center;
  }
  
  .userprofile-details img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .userprofile-info {
    flex: 1;
  }
  .userprofile-info h2{
    font-size: 18px;
    
  }
  .userprofile-info .email{
    max-width: calc(16.5px * 10); /* Assuming 16.5px is the width of each character */
  }
 .settingspage form {
    display: flex;
    flex-direction: column;
  }
  .heading3{
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .phoneh3{
    color:gray;
  }
 .settingspage input[type="text"],textarea,
 .settingspage select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #f1a923;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .settingspage [type="text"]:focus,
 .settingspage select:focus {
    outline: none;
    border-color: orange;
  }
  
  .settingspage .update {
    padding: 10px 20px;
    background-color: #c07474; /* Maroon color */
    border-radius: 5px;
    color:white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .settingspage .update:hover {
    background-color: #800000; /* Maroon color */
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }
  @media only screen and (max-width: 600px) {
    .backlink{
      margin-top: 15px;
      width: 100%;
    }
    .backtoaccounticon{
      float:left;
      justify-content: start;
      border-radius: 30px;
      margin-left: 15px;
    }
    .userprofile-info h2{
      font-size: 20px;
    }

    .heading3{
      font-size: 20px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .phoneh3{
      color:gray;
    }
  }